import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QueryClient, QueryClientProvider, useMutation } from "@tanstack/react-query";
import { Elements, useElements } from "@stripe/react-stripe-js";
import { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Subscription } from "src/storefront/types/subscription";
import { parseChangeSubscriptionPaymentMethodResponse, parsePaymentMethods } from "src/checkout/decoders";
import { PaymentMethod } from "src/checkout/model";
import { PaymentDetails } from "src/checkout/payment_details";
import { submitPayment } from "src/checkout/submit_payment";
import { ReactComponent as AlertCircleIcon } from "../../images/icons/alert-circle.svg";
import { ReactComponent as MoveExpandVerticalIcon } from "../../images/icons/move-expand-vertical.svg";
import { ReactComponent as MapsPin1 } from "../../images/icons/maps-pin-1.svg";
import { createPaymentMethod } from "src/checkout/create_payment_method";
import { SubmitButton } from "src/checkout/submit_button";
import { Temporal } from "@js-temporal/polyfill";
import { SubscriptionInvoice } from "src/checkout/subscription_invoice";
import { customerSubscriptionsPath, customerSubscriptionsResumeSubscriptionPath } from "../routes";
import { Popover } from "src/components/popover";

const queryClient = new QueryClient();
const Loading = () => {
  return (
    <>
      <section className="container width-constraint-narrow space-ml-none">
        <div className="skeleton is-paragraph" style={{ "--lines": 1 } as React.CSSProperties} />
        <div className="skeleton is-paragraph" style={{ "--lines": 6 } as React.CSSProperties} />
      </section>

      <hr className="space-m-none" />

      <section className="container width-constraint-narrow space-ml-none">
        <div className="skeleton is-paragraph" style={{ "--lines": 6 } as React.CSSProperties} />
      </section>
    </>
  );
}

const CustomerSubscriptionContainer = ({
  stripe = null,
  formElement,
  price,
  billingName,
  billingEmail,
  activeSubscription,
  isEligibleForTrial,
  trialPeriodDays,
  nextPaymentDate,
  hasReceivedInvoice
} : {
  stripe: Stripe | null;
  formElement: HTMLFormElement;
  price: number;
  activeSubscription?: Subscription;
  billingName: string;
  billingEmail: string;
  isEligibleForTrial: boolean;
  trialPeriodDays: number;
  nextPaymentDate: Temporal.PlainDate;
  hasReceivedInvoice: boolean;
}) => {
  if (!stripe) throw new Error("Stripe can't be found during paymentMethod creation");

  const formatPrice = (price: number) : string => {
    return (price / 100).toLocaleString("en-NZ", { style: "currency", currency: "NZD" });
  }

  const elementOptions : StripeElementsOptions = {
    paymentMethodCreation: 'manual',
    mode: 'payment',
    currency: 'nzd',
    amount: price,
    paymentMethodTypes: ['card'],
  };

  const overduePaymentCallout = () => {
    if (!activeSubscription?.isOverdue()) {
      return null;
    }


    return <section className="container is-flexbox justify-content-between align-items-baseline">
      <div className="callout has-border has-error flex-grow-1 is-flexbox">
        <AlertCircleIcon className="icon streamline-icon is-error-text"/>
        <div>
          <div className="title is-error-text">
            Your last payment failed
          </div>

          <p>
            Please update your card details before the next payment attempt to continue saving with Mates Rates.
          </p>
        </div>
      </div>
    </section>
  }

  const nextPaymentDateHeader = () => {
    if (!activeSubscription || activeSubscription.hasBeenCancelled()) return null;
    if (activeSubscription.isOverdue() && !activeSubscription.nextFailedPaymentRetry) return null;

    if (activeSubscription.isOverdue() && activeSubscription.nextFailedPaymentRetry) {
      return <section className="container is-flexbox justify-content-between align-items-baseline">
        <h5 className="space-mb-none">Next attempt</h5>
        <p className="is-bold-text is-muted-text">{activeSubscription.nextFailedPaymentRetry.toLocaleString("en-NZ", { dateStyle: "long" })}</p>
      </section>
    } else {
      return <section className="container is-flexbox justify-content-between align-items-baseline">
        <h5 className="space-mb-none">Next payment</h5>
        <p className="is-bold-text is-muted-text">{nextPaymentDate.toLocaleString("en-NZ", { dateStyle: "long" })}</p>
      </section>
    }
  }

  const eligibleAreas = () => {
    return <details className="collapse">
      <summary>
          <MapsPin1 className="icon streamline-icon space-mr-sm" />
          <span className="">Where is Mates Rates available?</span>
      </summary>

      <p>
        Great News! Mates Rates is currently rolling out across NZ. If your place is on this list — you&apos;re lucky enough to get access first.
      </p>

      <ul className="is-flex-gap-xs">
        <li>Dunedin</li>
        <li>Feilding</li>
        <li>Hastings</li>
        <li>Invercargill</li>
        <li>Kāpiti</li>
        <li>Napier</li>
        <li>Nelson</li>
        <li>New Plymouth</li>
        <li>Palmerston North</li>
        <li>Rotorua</li>
        <li>Whangārei</li>
        <li className="is-muted-text">More coming soon...</li>
      </ul>
    </details>
  }

  return (
    <>
      <SubscriptionNotices activeSubscription={activeSubscription} />

      <section className="container space-pt-none">
        <Headings activeSubscription={activeSubscription} trialPeriodDays={trialPeriodDays} isEligibleForTrial={isEligibleForTrial}/>
      </section>

      <hr className="space-m-none" />

      <section className="container">
        {eligibleAreas()}
      </section>

      <hr className="space-m-none" />
      {overduePaymentCallout()}

      {nextPaymentDateHeader()}

      <QueryClientProvider client={queryClient}>
        <Elements stripe={stripe} options={elementOptions}>
          <CustomerSubscription
            stripe={stripe}
            formElement={formElement}
            priceInCents={price}
            formattedPrice={formatPrice(price)}
            billingName={billingName}
            billingEmail={billingEmail}
            activeSubscription={activeSubscription}
            isEligibleForTrial={isEligibleForTrial}
            trialPeriodDays={trialPeriodDays}
            nextPaymentDate={nextPaymentDate}
            hasReceivedInvoice={hasReceivedInvoice}
          />
        </Elements>
      </QueryClientProvider>
    </>
  )
}

const SubscriptionNotices = ({
  activeSubscription
}: {
  activeSubscription?: Subscription;
}) => {
  if (activeSubscription?.hasBeenCancelled()) {
    return <section className="container">
      <div className="callout has-border">
        <p><b>Your subscription has been cancelled.</b> You&apos;ll still keep saving with Mates Rates until the end of {activeSubscription.currentPeriodEndAt.toLocaleString("en-NZ", { dateStyle: "long" })}.</p>
      </div>
    </section>;
  }

  return <></>;
};

const Headings = ({
  activeSubscription,
  trialPeriodDays,
  isEligibleForTrial,
}: {
  activeSubscription?: Subscription;
  trialPeriodDays: number;
  isEligibleForTrial: boolean;
}) => {
  if (!activeSubscription) {
    return (
      <>
        <h5 className="is-large-text-above-desktop">Benefits</h5>

        <ul className="has-emoji-markers is-large-text-above-desktop">
          <li data-marker="🤙">Get 50% off delivery from all our Mates Rates stores on orders over $20.</li>
          {isEligibleForTrial ? <li data-marker="👌">Try it free for {trialPeriodDays} days.</li> : <></>}
          <li data-marker="👀">Plus keep your eyes peeled for exclusive mates only deals coming soon...</li>
          {isEligibleForTrial ? <></> : <li className={"is-muted-text"} data-marker="👌">Your free {trialPeriodDays} day trial has ended.</li>}
        </ul>
      </>
    )
  }

  if (activeSubscription.hasBeenCancelled()) {
    return (
      <>
        <h5 className="is-large-text-above-desktop">Benefits</h5>

        <ul className="has-emoji-markers is-large-text-above-desktop">
          <li data-marker="🤙">Get 50% off delivery from all our Mates Rates stores on orders over $20.</li>
          {activeSubscription.isInTrial() ? <li data-marker="👌">Try it free for {trialPeriodDays} days. ({activeSubscription.currentPeriodDaysRemaining} days left)</li> : <></>}
          <li data-marker="👀">Plus keep your eyes peeled for exclusive mates only deals coming soon...</li>
          {!activeSubscription.isInTrial() ? <li className={"is-muted-text"} data-marker="👌">Your free {trialPeriodDays} day trial has ended.</li> : <></>}
        </ul>
      </>
    )
  }

  const trialDaysRemaining = () => {
    if (!activeSubscription.isInTrial())
      return;

    if (activeSubscription.currentPeriodDaysRemaining == 0) {
      return <li data-marker="👌">Your free trial <b>ends today</b>.</li>
    } else {
      return <li data-marker="👌">Your free trial has <b>{activeSubscription.currentPeriodDaysRemaining} day{activeSubscription.currentPeriodDaysRemaining === 1 ? "": "s"} left</b>.</li>
    }
  }

  return (
    <>
      <h5 className="is-large-text-above-desktop">You&apos;re on Mates Rates!</h5>
      <ul className="has-emoji-markers is-large-text-above-desktop">
        <li data-marker="🤙">Look for the Mates Rates indicator and add at least $20 worth of stuff to your cart to get half price delivery on your next order.</li>
        {trialDaysRemaining()}
        <li data-marker="👀">Plus keep your eyes peeled for exclusive mates only deals coming soon...</li>
        {!activeSubscription.isInTrial() ? <li className={"is-muted-text"} data-marker="👌">Your free {trialPeriodDays} day trial has ended.</li> : <></>}
      </ul>
    </>
  );
}

const CustomerSubscription = ({
  stripe,
  formElement,
  priceInCents,
  formattedPrice,
  billingName,
  billingEmail,
  activeSubscription,
  isEligibleForTrial,
  trialPeriodDays,
  nextPaymentDate,
  hasReceivedInvoice
}: {
  stripe: Stripe | null;
  formElement: HTMLFormElement;
  priceInCents: number;
  formattedPrice: string;
  billingName: string;
  billingEmail: string;
  activeSubscription?: Subscription;
  isEligibleForTrial: boolean;
  trialPeriodDays: number;
  nextPaymentDate: Temporal.PlainDate;
  hasReceivedInvoice: boolean;
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | undefined>(undefined);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState<PaymentMethod[] | undefined>(undefined);
  const [paymentDetailsComplete, setPaymentDetailsComplete] = useState<boolean>(false);
  const [cancellationRequestMessage, setCancellationRequestMessage] = useState<string>("");
  const [idempotencyKey, setIdempotencyKey] = useState<string>("");

  useEffect(() => {
    setIdempotencyKey(crypto.randomUUID());
  }, []);

  const elements = useElements();

  const handlePaymentSubmit: ({ submitPath }: { submitPath: string }) => Promise<void> = useCallback(async ({ submitPath }) => {
    if (selectedPaymentMethod === undefined && paymentDetailsComplete) {
      if (!stripe || !elements)
        throw new Error("Stripe can't be found during paymentMethod creation");

      const formData = new FormData(formElement)
      formData.set("idempotency-key", idempotencyKey);

      const newPaymentMethod = await createPaymentMethod({
        stripe: stripe,
        elements: elements,
        billingName: billingName,
        billingEmail: billingEmail,
        saveCard: false,
      });

      await submitPayment({
        stripe: stripe,
        paymentAmountInCents: priceInCents,
        paymentMethod: newPaymentMethod,
        confirmPath: submitPath,
        formData: formData,
      });
    } else if (selectedPaymentMethod !== undefined) {
      const formData = new FormData(formElement)
      formData.set("idempotency-key", idempotencyKey);

      await submitPayment({
        stripe: stripe,
        paymentAmountInCents: priceInCents,
        paymentMethod: selectedPaymentMethod,
        confirmPath: submitPath,
        formData: formData,
      });
    } else {
      throw new Error("No saved method or new payment method specified during confirm");
    }
  }, [
    billingEmail, billingName, elements, formElement,
    priceInCents, paymentDetailsComplete,
    selectedPaymentMethod, stripe, idempotencyKey
  ]);

  const {
    mutate: submitPaymentMutation,
    isPending: isLoadingSubmitPayment,
    isSuccess: isSuccessPayment,
    isError: isErrorSubmitPayment,
    error: errorSubmitPayment,
  } = useMutation<void, Error, { submitPath: string }>( { mutationFn: handlePaymentSubmit })

  const validPaymentMethod = paymentDetailsComplete && (selectedPaymentMethod === undefined || !selectedPaymentMethod.hasExpired());
  const isReadyToPay = validPaymentMethod && !isLoadingSubmitPayment && !isSuccessPayment;

  const submitPaymentError = useMemo(() => {
    if (!isErrorSubmitPayment) return null;

    return (
      <section className="container space-ml-none">
        <div className="callout has-border">
          <AlertCircleIcon className="icon streamline-icon" />
          <div>{errorSubmitPayment.message}</div>
        </div>
      </section>
    )
  }, [isErrorSubmitPayment, errorSubmitPayment?.message])

  useEffect(() => {
    fetchPaymentMethods().then((paymentMethods: PaymentMethod[]) => {
      if (paymentMethods.length > 0) {
        if (activeSubscription && !activeSubscription.hasBeenCancelled()) {
          const subscriptionPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === activeSubscription.defaultPaymentMethod);

          if (subscriptionPaymentMethod) {
            setSelectedPaymentMethod(subscriptionPaymentMethod);
            setPaymentDetailsComplete(true);
          } else {
            // Somehow the payment method for the subscription isn't attached to the customer
            // TODO: We'll need to reprompt for a card here - probably similar to an expired card situation which is post MVP
            throw "Mates Rates subscription payment method not attached to customer";
          }
        } else {
          const orderedPaymentMethodsByCardDefault = paymentMethods.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
          setSelectedPaymentMethod(orderedPaymentMethodsByCardDefault[0]);
          setPaymentDetailsComplete(true);
        }
      }

      setSavedPaymentMethods(paymentMethods)
    });
  }, [setSavedPaymentMethods, setSelectedPaymentMethod, activeSubscription]);

  const fetchPaymentMethods = async () : Promise<PaymentMethod[]> => {
    const resp = await fetch("/settings/payment_methods", {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    });

    if (!resp.ok)
      return [];

    const json = await resp.json();
    return parsePaymentMethods(json);
  }

  const cancelSubscription = useCallback(async () => {
    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;

    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }

    const csrfToken = metaElement.content;

    const body = {
      "message": cancellationRequestMessage,
    };

    const response = await fetch( "/mates_rates/cancel_subscription", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
    });

    if (response.ok) {
      window.location.href = "/mates_rates";
    }
  }, [cancellationRequestMessage])

  const submitPaymentMethodChange = async (paymentMethodId: string) => {
    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;
    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }
    const csrfToken = metaElement.content;
    const requestBody = {
      payment_method_id: paymentMethodId
    }

    const response = await fetch("/mates_rates/change_payment_method", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      body: JSON.stringify(requestBody)
    });

    const json = await response.json();

    return parseChangeSubscriptionPaymentMethodResponse(json);
  }

  const changePaymentMethod = useCallback(async (newPaymentMethod: PaymentMethod) => {
    // if we don't have an active subscription, then changing the card is just part of signup flow that is yet to be confirmed so just update the card here
    if (!activeSubscription || activeSubscription.hasBeenCancelled()) {
      setSelectedPaymentMethod(newPaymentMethod);
    } else {
      // If we do have an active subscription, then submit the changed card
      const submitPaymentResponse = await submitPaymentMethodChange(newPaymentMethod.id);

      if (submitPaymentResponse.error) {
        return submitPaymentResponse.error;
      } else {
        const updatedPaymentMethods = await fetchPaymentMethods();
        setSavedPaymentMethods(updatedPaymentMethods)
        setSelectedPaymentMethod(newPaymentMethod);
      }
    }
  }, [activeSubscription]);

  const checkoutInfo = useMemo(() => {
    if (activeSubscription?.eligibleForSubscriptionBenefits && !activeSubscription?.hasBeenCancelled()) {
      return <></>;
    } else {
      return <>
        <section className="container">
          <div className="is-flexbox justify-content-between align-items-baseline is-bold-text">
            <h5 className="is-large-text-above-desktop">Join Mates Rates</h5>
            <p className="is-large-text-above-desktop">{formattedPrice}<span className="is-muted-text"> / month</span></p>
          </div>

          <p className="is-tiny-text is-muted-text">You will be charged {formattedPrice} monthly {isEligibleForTrial ? "after trial end " : ""}starting {nextPaymentDate.toLocaleString("en-NZ", { dateStyle: "long" })}. Delivery discounts for eligible stores only. $20 min spend per order applies.</p>

          <p className="is-tiny-text is-muted-text">By joining Mates Rates you have confirmed to have read and agree to the full <a className="has-underline is-muted-text" href="/legal">Terms and Conditions</a>.</p>
        </section>
      </>;
    }
  }, [activeSubscription, formattedPrice, nextPaymentDate, isEligibleForTrial]);

  const checkoutActionInfo = useMemo(() => <>
    <div className="container">
      <div className="text-overflow-toggle" style={{ "--line-clamp": 2, "--collapse-label": "'Read less'" } as React.CSSProperties}>
        <input className="is-completely-hidden" name="expand_description" id="expand_description" type="checkbox" />

        <p className="is-muted-text is-tiny-text space-mb-none">
          By joining Delivereasy Mates Rates you authorise Delivereasy to charge {formattedPrice} on or around {nextPaymentDate.toLocaleString("en-NZ", { dateStyle: "long" })} (your billing day), and once every plan cycle, until cancelled, using any stored payment method.
          <br /><br />
          Cancel in app or online through the Delivereasy platform up to 48 hours before your {isEligibleForTrial ? "trial ends " : "next billing date "}to avoid incurring the charge for your next billing cycle, or contact support. Subject to consumer laws, your recurring payment is not refundable.
        </p>

        <label htmlFor="expand_description" className="is-muted-text is-tiny-text">
          <MoveExpandVerticalIcon className="icon streamline-icon is-muted-text" />
          <span>Read more</span>
        </label>
      </div>
    </div>
  </>, [formattedPrice, nextPaymentDate, isEligibleForTrial])

  const unexpandedCancellationPopover = (showPopover: () => void ) => {
    return (
      <section className="container">
        <SubmitButton buttonCopy={"Cancel subscription"} isPrimary={false} enabled={true} onSubmit={async () => showPopover()} hasBottomSpacing={false} dataTestId={'cancel_subscription'} />
      </section>
    )
  }

  const expandedCancellationPopover = useCallback((hidePopover: () => void) => {
    return (
      <section className="container">
        <h5 className="space-mb-sm">Are you sure you want to cancel?</h5>
        {activeSubscription?.isOverdue() ? <></> : <p>No worries — you’ll still keep saving with Mates Rates until {activeSubscription?.currentPeriodEndAt.toLocaleString("en-NZ", { dateStyle: "long" })}.</p>}

        <div className="space-mb-sm"><label>Feedback <span className="is-tiny-muted-text">(optional)</span></label></div>
        <div className="textarea">
          <textarea
            name="cancellationRequest"
            id="cancellationRequest"
            onChange={(evt) => setCancellationRequestMessage(evt.target.value)}
            value={cancellationRequestMessage}
            maxLength={255}
          />
        </div>

        <SubmitButton buttonCopy={"Cancel subscription"} isPrimary={true} enabled={true} onSubmit={cancelSubscription} hasBottomSpacing={true} dataTestId={'cancel_subscription_modal'} />
        <SubmitButton buttonCopy={"No – take me back"} isPrimary={false} enabled={true} onSubmit={async () => hidePopover()} hasBottomSpacing={false} dataTestId={'take_me_back'} />
      </section>
    )
  }, [cancelSubscription, activeSubscription, cancellationRequestMessage])

  const checkoutAction = useMemo(() => {
    if (activeSubscription?.eligibleForSubscriptionBenefits && !activeSubscription?.hasBeenCancelled()) {
      return <>
        <Popover
          unexpandedContent={unexpandedCancellationPopover}
          expandedContent={expandedCancellationPopover}
          options={{ isModal: true, isFloatingOnDesktop: true }}
        />

        <div className="container">
          <p className="is-tiny-text is-muted-text">Discount applicable to delivery fees only, service fees not eligible for discount. Delivery benefits only available for eligible stores as displayed on the platform at the time of purchase. Eligible stores may change from time to time.</p>
          <p className="is-tiny-text is-muted-text">$20 minimum spend (excluding service and delivery fees) required to receive delivery benefits. Delivery fee discount visible at checkout.</p>
          <p className="is-tiny-text is-muted-text">Avoid incurring the charge for the next billing cycle by cancelling in app or online through the Delivereasy platform 48 hours before your next billing date or contacting support.</p>
        </div>
      </>
    } else if (activeSubscription?.eligibleForSubscriptionBenefits && activeSubscription?.hasBeenCancelled()) {
      const buttonCopy = activeSubscription.isInTrial() ? `Resume free trial (${activeSubscription.currentPeriodDaysRemaining} days left)` : `Resume subscription`

      return <>
        <section className="container">
          <SubmitButton buttonCopy={buttonCopy} isPrimary={true} enabled={isReadyToPay} onSubmit={async () => submitPaymentMutation({ submitPath: customerSubscriptionsResumeSubscriptionPath() })} hasBottomSpacing={true} />
        </section>

        {checkoutActionInfo}
      </>
    } else {
      const buttonCopy = isEligibleForTrial ? `Start free ${trialPeriodDays} day trial` : `Subscribe for ${formattedPrice}`

      return <>
        <section className="container">
          <SubmitButton buttonCopy={buttonCopy} isPrimary={true} enabled={isReadyToPay} onSubmit={async () => submitPaymentMutation({ submitPath: customerSubscriptionsPath() })} hasBottomSpacing={true} />
        </section>

        {checkoutActionInfo}
      </>
    }

  }, [activeSubscription, formattedPrice, isReadyToPay, isEligibleForTrial, trialPeriodDays, checkoutActionInfo, expandedCancellationPopover, submitPaymentMutation]);

  const confirmButtonCopy = activeSubscription && !activeSubscription.hasBeenCancelled() ? "Confirm change" : "Continue"

  if (!savedPaymentMethods) {
    return <Loading />;
  }

  return <>
    <PaymentDetails
      savedPaymentMethods={savedPaymentMethods}
      selectedPaymentMethod={selectedPaymentMethod}
      setSelectedPaymentMethod={changePaymentMethod}
      setPaymentDetailsComplete={setPaymentDetailsComplete}
      billingName={billingName}
      billingEmail={billingEmail}
      allowSaveCard={false} // Will always be saved by default when creating the subscription anyway
      onSaveCardChange={() => {console.log("TODO")}} // TODO
      enabledPaymentMethods={["card"]}
      confirmButtonCopy={confirmButtonCopy}
    />

    {hasReceivedInvoice ? (
      <SubscriptionInvoice />
    ): <></>}


    {submitPaymentError}

    <hr />

    {checkoutInfo}

    {checkoutAction}
  </>
}

export { CustomerSubscriptionContainer }
