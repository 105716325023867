import { PaymentIntentOrSetupIntentResult, Stripe } from "@stripe/stripe-js";
import { parseConfirmForPaymentResponse } from "./decoders";
import { PaymentMethod, WalletType } from "./model";

const STRIPE_MINIMUM_CHARGE = 50; // $0.50NZD

interface SubmitPaymentParams {
  paymentAmountInCents: number;
  paymentMethod?: PaymentMethod;
  formData: FormData;
  confirmPath: string;
  stripe: Stripe | null;
}

const submitPayment = async ({
  stripe,
  paymentAmountInCents,
  paymentMethod,
  confirmPath,
  formData,
} : SubmitPaymentParams) => {
  formData.set("payment_amount", paymentAmountInCents.toString());
  formData.set("authenticity_token", (document.querySelector("meta[name='csrf-token']") as HTMLMetaElement)?.content || "");

  if (paymentAmountInCents >= STRIPE_MINIMUM_CHARGE) {
    if (!paymentMethod) {
      throw new Error("Can't confirm a payment without a payment method");
    }

    formData.set("payment_method_id", paymentMethod.id);

    if (paymentMethod.type == "card" && paymentMethod.walletType == WalletType.None && paymentMethod.saveCard)
      formData.set("setup_future_usage", "on_session");
  }

  const req = await fetch(confirmPath, {
    method: "POST",
    credentials: "include",
    body: formData,
    redirect: "manual",
  });

  if (req.type == "opaqueredirect") {
    // redirects are only used in error cases; annoyingly, we can't see the redirect URL,
    // so the best we can do is redirect and hope the flash is useful
    window.location.reload();
    
    return;
  }

  if (req.status != 200) 
    throw new Error(`Unexpected response from payment intent confirmation: ${req.status}`);

  const jsonData = await req.json();
  const resp = parseConfirmForPaymentResponse(jsonData);

  if (!resp.success) {
    if (resp.next) {
      window.location.assign(resp.next);
      return;
    } else if (resp.error) {
      handlePaymentSubmitError(resp.error);
      return;
    } else {
      throw new Error("Payment intent wasn't confirmed when we expected it.");
    }
  }

  if (resp.paymentIntentStatus === "requires_action") {
    if (!stripe)
    throw new Error("Can't load Stripe which we need to confirm the paymentIntent nextActions");

    const result = await handleNextActions(stripe, resp.paymentIntentSecret);
    if (result.error) {
      handlePaymentSubmitError(result.error.message);
      return;
    }
  }

  window.location.assign(resp.next);
}

const handlePaymentSubmitError = (errorMessage?: string) => {
  throw new Error(errorMessage || "An error occurred.");
}

const handleNextActions = async (stripe: Stripe, paymentIntentSecret?: string): Promise<PaymentIntentOrSetupIntentResult> => {
  if (!paymentIntentSecret) throw "Missing payment intent secret";

  return await stripe.handleNextAction({
    clientSecret: paymentIntentSecret
  });
}

export { submitPayment, SubmitPaymentParams, STRIPE_MINIMUM_CHARGE };
