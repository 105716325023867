import { JsonDecoder } from "ts.data.json";
import * as Sentry from "@sentry/browser";
import { ConfirmForPaymentResponse, FailureConfirmForPaymentResponse, PaymentIntentStatus, PaymentMethod, PaymentMethodCard, SuccessConfirmForPaymentResponse, WalletType } from "./model";
import { Invoice } from "src/storefront/types/invoice";

interface RemotePaymentMethodExpiry {
  year: number;
  month: number;
}

interface RemotePaymentMethodCard {
  type: string;
  id: string;
  brand: string;
  expiry: RemotePaymentMethodExpiry;
  description: string;
}

interface RemoteInvoice {
  id: number;
  invoiceStripeId: string;
  created: string;
  amountPaid: number;
  currentPeriodStart: string;
  currentPeriodEnd: string;
}

const paymentMethodDecoder = JsonDecoder.object<RemotePaymentMethodCard>({
  "id": JsonDecoder.string,
  "type": JsonDecoder.isExactly("card"),
  "brand": JsonDecoder.string,
  "expiry": JsonDecoder.object({
    "year": JsonDecoder.number,
    "month": JsonDecoder.number,
  }, "expiry"),
  "description": JsonDecoder.string
}, "PaymentMethod");

const invoiceDecoder = JsonDecoder.object<RemoteInvoice>({
  "id": JsonDecoder.number,
  "created": JsonDecoder.string,
  "amountPaid": JsonDecoder.number,
  "invoiceStripeId": JsonDecoder.string,
  "currentPeriodStart": JsonDecoder.string,
  "currentPeriodEnd": JsonDecoder.string,
},"Invoice");

const paymentMethodResponseDecoder = JsonDecoder.object({
  "payment_methods": JsonDecoder.array(paymentMethodDecoder, "PaymentMethod[]"),
  "default_payment_method": JsonDecoder.optional(JsonDecoder.string),
}, "PaymentMethodResponse");

const invoiceResponseDecoder = JsonDecoder.object({
  "invoices": JsonDecoder.array(invoiceDecoder, "Invoice[]"),
}, "InvoiceResponse")

const assemblePaymentMethods = (interPaymentMethods: RemotePaymentMethodCard[], defaultPaymentMethod?: string) : PaymentMethod[] => {
  return interPaymentMethods.map(ipm => {
    return new PaymentMethodCard({
      id: ipm.id,
      brand: ipm.brand,
      walletType: WalletType.None,
      expiryYear: ipm.expiry.year,
      expiryMonth: ipm.expiry.month,
      description: ipm.description,
      saveCard: false,
      isDefault: defaultPaymentMethod == ipm.id
      });
  });
};

const assembleInvoices = (interInvoices: RemoteInvoice[]) => {
  return interInvoices.map(inv => {
    return new Invoice({
      created: inv.created,
      amountPaid: inv.amountPaid,
      invoiceStripeId: inv.invoiceStripeId,
      id: inv.id,
      currentPeriodStart: inv.currentPeriodStart,
      currentPeriodEnd: inv.currentPeriodEnd
    })
  })
}

const parsePaymentMethods = (json: unknown) : PaymentMethod[] => {
  const result = paymentMethodResponseDecoder.decode(json);
  if (result.isOk())
  return assemblePaymentMethods(result.value.payment_methods, result.value.default_payment_method);
  else {
    Sentry.captureMessage(`Failed to parse paymentMethods: ${json}`);
    return [];
  }
}

const parseInvoices = (json: unknown) : Invoice[] => {
  const result = invoiceResponseDecoder.decode(json);
  if (result.isOk())
    return assembleInvoices(result.value.invoices)
  else {
    Sentry.captureMessage(`Failed to parse response confirmForPaymentResponse: ${JSON.stringify(json)}, error: ${result.error}`)
    return [];
  }

}

type RemoteConfirmForPaymentResponse = RemoteSuccessConfirmForPaymentResponse | RemoteFailureConfirmForPaymentResponse;

interface RemoteSuccessConfirmForPaymentResponse {
  success: boolean;
  next: string;
  payment_intent_secret?: string;
  payment_intent_status?: PaymentIntentStatus;
}

interface RemoteFailureConfirmForPaymentResponse {
  success: boolean;
  error: string;
  next?: string;
}

const paymentIntentStatusDecoder = JsonDecoder.oneOf<PaymentIntentStatus>([
  JsonDecoder.isExactly("succeeded"),
  JsonDecoder.isExactly("requires_action"),
  JsonDecoder.isExactly("not_required"),
], "PaymentIntentStatus");

const successConfirmForPaymentResponseDecoder = JsonDecoder.object<RemoteSuccessConfirmForPaymentResponse>({
  success: JsonDecoder.isExactly(true),
  next: JsonDecoder.string,
  payment_intent_status: JsonDecoder.optional(paymentIntentStatusDecoder),
  payment_intent_secret: JsonDecoder.optional(JsonDecoder.string),
}, "SuccessConfirmForPaymentResponse");

const failureConfirmForPaymentResponseDecoder = JsonDecoder.object<RemoteFailureConfirmForPaymentResponse>({
  success: JsonDecoder.isExactly(false),
  error: JsonDecoder.string,
  next: JsonDecoder.optional(JsonDecoder.string),
}, "FailureConfirmForPaymentResponse");

const confirmForPaymentResponseDecoder = JsonDecoder.oneOf<RemoteConfirmForPaymentResponse>([
  successConfirmForPaymentResponseDecoder,
  failureConfirmForPaymentResponseDecoder,
], "ConfirmForPaymentResponse");

const assembleConfirmForPaymentResponse = (resp: RemoteConfirmForPaymentResponse) : ConfirmForPaymentResponse => {
  if (resp.success) {
    const rsucc = resp as RemoteSuccessConfirmForPaymentResponse;
    return new SuccessConfirmForPaymentResponse(
      rsucc.next,
      rsucc.payment_intent_status,
      rsucc.payment_intent_secret
    );
  } else {
    const rfail = resp as RemoteFailureConfirmForPaymentResponse;
    return new FailureConfirmForPaymentResponse(
      rfail.error,
      rfail.next
    );
  }
}

const parseConfirmForPaymentResponse = (json: unknown): ConfirmForPaymentResponse => {
  const result = confirmForPaymentResponseDecoder.decode(json);
  if (result.isOk())
    return assembleConfirmForPaymentResponse(result.value);
  else {
    throw new Error(`Failed to parse response confirmForPaymentResponse: ${JSON.stringify(json)}, error: ${result.error}`);
  }
}

interface ChangeSubscriptionPaymentMethodResponse {
  error?: string;
}

const ChangeSubscriptionPaymentMethodResponseDecoder = JsonDecoder.object<ChangeSubscriptionPaymentMethodResponse>({
  error: JsonDecoder.optional(JsonDecoder.string),
}, "ChangeSubscriptionPaymentMethodResponse");

const parseChangeSubscriptionPaymentMethodResponse = (json: unknown): ChangeSubscriptionPaymentMethodResponse => {
  const result = ChangeSubscriptionPaymentMethodResponseDecoder.decode(json);

  if (result.isOk())
    return result.value;
  else {
    throw new Error(`Failed to parse response confirmForPaymentResponse: ${JSON.stringify(json)}, error: ${result.error}`);
  }
}

export { parsePaymentMethods, parseConfirmForPaymentResponse, parseChangeSubscriptionPaymentMethodResponse, parseInvoices };
