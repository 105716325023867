import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("intersection-observer");

require.context('images', true);

import "./stylesheets/customer.scss";

// Polyfill for browsers that don't support smooth scrolling behaviour
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// Polyfill to add support for svh, dvh and lvh viewport units in older browsers
import 'large-small-dynamic-viewport-units-polyfill';

// Configure Stimulus
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();

const sharedContext = require.context("controllers/shared", true, /\.(ts|js)$/);
application.load(definitionsFromContext(sharedContext));

const customerContext = require.context("controllers/customer", true, /\.(ts|js|tsx)$/);
application.load(definitionsFromContext(customerContext));

// Google maps tie-in to the Stimulus controller
declare global {
  interface Window {
    initGoogleMaps: () => void;
    LoadNotification: any;
    googleMapsLoaded: boolean;
    ConfigureInAppDomains: MessagePort;
  }
}

window.initGoogleMaps = function() {
  if (document.readyState == "loading") {
    window.addEventListener("DOMContentLoaded", googleMapsIsReady);
  } else {
    googleMapsIsReady();
  }
}

const googleMapsIsReady = () => {
  window.googleMapsLoaded = true;
  const ev = new Event("googleMapsCallback", { bubbles: true });
  window.dispatchEvent(ev);
}

const notifyLoaded = () => {
  if (window.LoadNotification !== undefined) {
    window.LoadNotification.postMessage('PageLoaded');
  }
}

const configureInAppDomains = () => {
  if (window.ConfigureInAppDomains !== undefined) {
    // Recent version of the customer app; configure it to support the domains we currently see used
    // to handle Stripe's redirects to Afterpay. The domains aren't documented anywhere, so we're
    // sending them from the server rather than hardcoding them and needing to update the customer
    // app if they change.  This also gives us the detection that it's a version that works, below.
    window.ConfigureInAppDomains.postMessage(JSON.stringify([
      "hooks.stripe.com",
      "portal.afterpay.com",
      "stripe.com", // used for test mode
    ]));
  }
}

if (document.readyState == "loading") {
  document.addEventListener("DOMContentLoaded", () => {
    notifyLoaded();
    configureInAppDomains();
  });
} else {
  notifyLoaded();
  configureInAppDomains();
}

// Emit an event that can be picked up by Stimulus on reCAPTCHA success
declare global {
  interface Window {
    recaptchaSucceeded: () => void;
  }
}

window.recaptchaSucceeded = function () {
  window.dispatchEvent(new Event("recaptchaSucceeded"));
}
