import React from "react"
import { createRoot } from "react-dom/client";

import { Controller } from "@hotwired/stimulus";
import algoliasearch, { SearchClient } from "algoliasearch/lite";

import Autocomplete from "src/components/autocomplete";
import StoresAutocompleteConfig from "src/components/autocomplete/stores_autocomplete_config";
import ProductAutocompleteConfig from "src/components/autocomplete/product_autocomplete_config";
import { FulfillmentMethod } from "src/storefront/types/catalog";

export default class AlgoliaAutocomplete extends Controller {
  static values = {
    algoliaAppId: String,
    algoliaPublicApiKey: String,
    algoliaIndexName: String,
    algoliaIndexType: String,
    initialQuery: String,
    storeIds: Array<number>,
    excludeAgeRestrictedStores: Boolean,
    storeId: Number,
    storePermalink: String,
    imageBaseUrlDetails: Object,
    fulfillmentMethod: String,
    currentCustomerId: String,
  }

  static targets = ["queryInput", "clearInput", "popoverContainer"];

  declare algoliaAppIdValue?: string;
  declare algoliaPublicApiKeyValue?: string;
  declare algoliaIndexNameValue?: string;
  declare algoliaIndexTypeValue?: string;
  declare initialQueryValue?: string;
  declare storeIdsValue?: number[];
  declare excludeAgeRestrictedStoresValue?: boolean;
  declare storeIdValue?: number;
  declare storePermalinkValue?: string;
  declare imageBaseUrlDetailsValue?: { protocol: string; domain: string; port?: number };
  declare fulfillmentMethodValue?: FulfillmentMethod;
  declare currentCustomerIdValue?: string;
  declare queryInputTargets: HTMLInputElement[];
  declare clearInputTargets: HTMLElement[];
  declare popoverContainerTarget: HTMLElement;

  connect() {
    if (!this.algoliaAppIdValue || !this.algoliaPublicApiKeyValue) {
      throw "Missing autocomplete configuration";
    }

    if (this.queryInputTargets.length == 0) {
      throw "Missing autocomplete input target";
    }

    const resultsPanelElement = this.element.querySelector("#autocomplete-results");

    if (resultsPanelElement === null) {
      throw "Cannot find element target with #autocomplete-results"
    }

    const searchClient = algoliasearch(this.algoliaAppIdValue, this.algoliaPublicApiKeyValue, {
      headers: {
        'X-Algolia-UserToken': `${this.currentCustomerIdValue}`
      }
    });

    const initialQuery = this.initialQueryValue || "";

    const root = createRoot(resultsPanelElement);

    root.render(this.buildAutocompleteWidget(searchClient, initialQuery));
  }

  buildAutocompleteWidget(searchClient: SearchClient, initialQuery: string) {
    if (!this.algoliaIndexNameValue) throw "Missing index name";
    if (!this.imageBaseUrlDetailsValue) throw "Missing base url details";
    if (!this.algoliaIndexTypeValue) throw "Missing index type";

    if (this.algoliaIndexTypeValue == "stores") {
      const config = new StoresAutocompleteConfig(this.algoliaIndexNameValue, this.imageBaseUrlDetailsValue, this.storeIdsValue, this.excludeAgeRestrictedStoresValue)

        return <Autocomplete
          searchClient={searchClient}
          autocompleteConfig={config}
          inputElements={this.queryInputTargets}
          clearInputElements={this.clearInputTargets}
          popoverContainer={this.popoverContainerTarget}
          initialQuery={initialQuery}
        />

    } else if (this.algoliaIndexTypeValue == "products") {
      if (!this.storeIdValue) throw "Missing storeId for products index";
      if (!this.storePermalinkValue) throw "Missing storePermalink for products index";
      if (!this.fulfillmentMethodValue) throw "Missing fulfillment method for products index";
      const config = new ProductAutocompleteConfig(this.algoliaIndexNameValue, this.imageBaseUrlDetailsValue, this.storeIdValue, this.storePermalinkValue, this.fulfillmentMethodValue);

        return <Autocomplete
          searchClient={searchClient}
          autocompleteConfig={config}
          inputElements={this.queryInputTargets}
          clearInputElements={this.clearInputTargets}
          popoverContainer={this.popoverContainerTarget}
          initialQuery={initialQuery}
        />
    } else {
      throw `Unrecognised index type: ${this.algoliaIndexTypeValue}`;
    }
  }
}
