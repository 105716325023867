import React from "react";

import AutocompleteConfig, { ImageBaseUrl } from "src/components/autocomplete/autocomplete_config";
import { BaseItem } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import {
  restaurantPath,
  searchRestaurantsPath,
  storeAttachmentImageryUrl,
  storefrontsPastStoresPath
} from "src/routes";
import { SearchClient } from "algoliasearch/lite";
import { AutocompleteResultComponent } from "src/components/autocomplete_renderer";

export interface StoreResult extends BaseItem {
  objectID: string;
  name: string;
  slug: string;
  image_thumbnail_id?: number;
}

export default class StoresAutocompleteConfig extends AutocompleteConfig<StoreResult> {
  storeIds: Array<number>;
  excludeAgeRestrictedStores: boolean;

  constructor(indexName: string, imageBaseUrl: ImageBaseUrl, storeIds?: Array<number>, excludeAgeRestrictedStores?: boolean) {
    super(indexName, imageBaseUrl);

    this.storeIds = storeIds || [];
    this.excludeAgeRestrictedStores = excludeAgeRestrictedStores || false;
  }

  emptyQueryCollectionId = "past_stores";
  emptyQueryCollectionTitle = "Order again";
  queryCollectionId = "stores"

  filterString(): string {
    const storesFilter = `(${this.storeIds.map((store_id) => `objectID:${store_id}`).join(" OR ")})`;
    const ageRestrictedFilter = this.excludeAgeRestrictedStores ? " AND requires_age_verification:false" : ""

    return storesFilter + ageRestrictedFilter;
  }

  emptyQueryResults() {
    return {
      sourceId: this.emptyQueryCollectionId,
      async getItems() {
        const resp = await fetch(storefrontsPastStoresPath(), { headers: { "Content-Type": "application/json" } });

        return (await resp.json() as { results: StoreResult[] } )["results"];
      },
      templates: {
        item() {
          return '<div></div>'
        }
      }
    }
  }

  queryResults(searchClient: SearchClient) {
    return {
      sourceId: this.queryCollectionId,
      getItems: ({ query }: { query: string }) => {
        if (query.length == 0) return [];
        return getAlgoliaResults<StoreResult>({
          searchClient,
          queries: [
            {
              indexName: this.indexName,
              query,
              params: {
                hitsPerPage: 5,
                filters: this.filterString(),
              },
            },
          ],
        });
      },
      templates: {
        item() {
          return '<div></div>'
        }
      }
    };
  }

  searchResultsPath(query: string) {
    return searchRestaurantsPath({ "search_bar": query });
  }

  itemComponent(item: StoreResult): React.JSX.Element {
    return <AutocompleteResultComponent key={item.objectID} name={item.name} href={restaurantPath(item.slug)} imageUrl={item.image_thumbnail_id ? storeAttachmentImageryUrl(item.image_thumbnail_id, { ...this.imageBaseUrl }) : undefined} />
  }
}
